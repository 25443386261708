<template>
  <v-autocomplete v-bind="$attrs" :items="availableItems" :label="label" v-model="selected" :item-text="itemText"
      
     
      item-value="ID" 
     
       
     
     
      :clearable="clearable" :search-input.sync="searchInput" :loading="loading"
    @change="searchInput=''" :rules="rules"></v-autocomplete>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  export default {
    name: 'autocomplete-LuBusinessRole',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        default: 'Lu Business Role',
      },
      value: Number,
      clearable: {
        type: Boolean,
        default: false
      },
      rules: Array,
    },
    data() {
      return {
      itemText: 'Description',
      searchInput: '',
      }
    },
    watch: {
      value(nval, oval) {
        if (nval != oval) {
          this.searchInput = ''
        }
      },
    },
  computed: {
    ...get('luBusinessRole', ['luBusinessRole', 'loading']),
    availableItems() {
      //if you have a property (like an IsActive boolean) you can change the true to filer i.e. i.IsActive
        var list = this.luBusinessRole.filter((i) => true)
          // if existing record make sure the previous selection is available even if no longer active
        if (this.value > 0) {
            var selectedItem = this.luBusinessRole.find((i) => i.ID == this.value)
          if (!list.find((i) => i.ID == this.value) && selectedItem) {
            list.push(selectedItem)
          }
        }
        // sort by column
        list.sort((a, b) => {
          //set the property to sort on
          let textField = this.itemText
          let sort = a[textField] > b[textField] ? 1 : -1
          return sort
        })
        return list
      },
      selected: {
        get() {
          if (this.value) {
            return this.value
          } else {
            return null
          }
        },
        set(newValue) {
          this.$emit('change', newValue)
        }
      }
    },
    created(){
      this.loadLuBusinessRole ()
    },
    methods: {
      loadLuBusinessRole: call('luBusinessRole/loadLuBusinessRole'),
    },
  }
</script>
<style>

</style>