<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary headline text-left white--text">
                <div>
                  Business Users
                  <v-btn
                    color="white"
                    class="primary--text ml-4"
                    small
                    @click.native.stop="addBusinessUser"
                  >Add New User</v-btn>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text class="headline">
                  <v-data-table
                    :headers="filteredHeaders"
                    :items="selectedBusiness.UserBusiness"
                    :search="searchText"
                    class="pt-4"
                    :loading="loading"
                    dense
                  >
                    <template v-slot:item.edit="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="editUserBusiness(item)"
                          >
                            <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit UserBusiness Information</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-slot:item.IsActive="{ item }"
                    >{{ item.IsActive? 'Active': 'Inactive'}}</template>
                    <!-- <template v-slot:item.delete="{item}">
                      <v-tooltip v-if="item.ID > 0" bottom>
                        <template v-slot:activator="{on}">
                          <v-btn small color="primary" v-on="on" icon @click="deleteConfirm(item)">
                            <v-icon class="clickable" color="primary">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete UserBusiness</span>
                      </v-tooltip>
                    </template>-->
                  </v-data-table>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog" width="750">
      <v-card>
        <v-card-title class="primary">
          <h3 class="primary headline text-left white--text">
            {{
            modalText
            }}
          </h3>
        </v-card-title>
        <v-card-text class="headline">
          <v-row>
            <v-col sm="12">
              <v-form ref="addBusinesUserForm" @submit.prevent lazy-validation v-model="valid">
                <div v-if="selectedUserBusiness.User.ID == 0">
                  <v-row>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model="selectedUserBusiness.User.FirstName"
                        label="First Name"
                        :rules="[(v) => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model="selectedUserBusiness.User.LastName"
                        label="Last Name"
                        :rules="[(v) => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="6">
                      <v-text-field
                        v-model="selectedUserBusiness.User.Email"
                        label="Email"
                        :rules="[(v) => !!v || 'required']"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6">
                      <LuBusinessRole
                        v-model="selectedUserBusiness.BusinessRoleID"
                        label="User Role"
                      ></LuBusinessRole>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="4">
                      <v-checkbox label="Active?" v-model="selectedUserBusiness.IsActive"></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row>
                    <v-col sm="12" md="4">
                      <LuBusinessRole
                        v-model="selectedUserBusiness.BusinessRoleID"
                        label="User Role"
                      ></LuBusinessRole>
                    </v-col>
                    <v-col sm="12" md="4">
                      <v-checkbox label="Active?" v-model="selectedUserBusiness.IsActive"></v-checkbox>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="primary" @click="saveBusinessUser">Save</v-btn>
            <v-btn color="primary" @click="closeDialog">Close</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import UserBusiness from '@classes/UserBusiness'
import User from '@classes/User'
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import datePickerWithText from '@components/datePickerWithText'
import LuState from '@components/select/a-single/LuState.vue'
import LuBusinessRole from '@components/select/a-single/LuBusinessRole.vue'
import { mask } from 'vue-the-mask'
export default {
  directives: {
    mask,
  },
  page: {
    title: 'BusinessUsers',
  },
  components: {
    datePickerWithText,
    LuState,
    LuBusinessRole,
  },
  data() {
    return {
      valid: true,
      dialog: false,
      modalText: '',
      searchText: '',
      panel: [0, 1],
      selectedUserBusiness: new UserBusiness(),
      phoneMask: '(###)  ###-####',
      extMask: '###',
      emailRules: [
        (v) =>
          !v ||
          /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'Valid email format example@email.com',
      ],
    }
  },
  created() {},
  computed: {
    ...authComputed,
    ...get('userBusiness', ['userBusiness', 'saving', 'loading']),
    ...get('business', ['selectedBusiness']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'First Name',
          align: 'center',
          value: 'User.FirstName',
          visible: true,
          sortable: false,
        },
        {
          text: 'Last Name',
          align: 'center',
          value: 'User.LastName',
          visible: true,
          sortable: false,
        },
        {
          text: 'Email',
          align: 'center',
          value: 'User.Email',
          visible: true,
          sortable: false,
        },
        {
          text: 'Role',
          align: 'center',
          value: 'BusinessRole.Description',
          visible: true,
          sortable: false,
        },
        {
          text: 'Active?',
          align: 'center',
          value: 'IsActive',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    userRoleDescription() {
      let roleDescription = this.selectedBusiness.UserBusiness.map((item) => {
        return {
          ...item,
          Description: item.BusinessRole.Description,
        }
        console.log(roleDescription)
        if (roleDescription.length > 0) {
          return roleDescription[0]
        } else {
          return {}
        }
      })
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('userBusiness', [
      'saveUserBusiness',
      'deleteUserBusiness',
      'loadUserBusiness',
      'refreshSelectedUserBusiness',
      'clearLookupCacheAndReloadUserBusiness',
    ]),
    ...call('business', ['refreshSelectedBusiness']),
    async validate() {
      this.$refs.addBusinesUserForm.validate()
    },
    editUserBusiness(entry) {
      this.selectedUserBusiness = new UserBusiness(entry)
      this.modalText = 'Edit User'
      this.dialog = !this.dialog
    },
    addBusinessUser() {
      this.modalText = 'Add User'
      this.selectedUserBusiness = new UserBusiness()
      this.dialog = !this.dialog
    },
    closeDialog() {
      this.dialog = !this.dialog
    },
    saveBusinessUser() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedUserBusiness.BusinessID = this.selectedBusiness.ID
          this.saveUserBusiness(this.selectedUserBusiness.getSaveData()).then(
            (res) => {
              this.selectedUserBusiness = new UserBusiness()
              this.$refs.addBusinesUserForm.resetValidation()
              this.refreshSelectedBusiness()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
  },
  watch: {},
}
</script>
<style>
.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: white !important;
}
</style>
