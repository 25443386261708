<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-btn class="primary mr-2" @click="openDialog" small
          >Edit Business Information</v-btn
        >
        <v-btn
          class="primary mr-2"
          small
          :to="`/hempApplication/${selectedBusiness.ID}`"
          >Apply for Hemp License</v-btn
        >
        <v-btn
          v-if="unconfirmedBusinesses.length > 0"
          class="primary"
          small
          :to="`/confirmBusiness`"
          >Confirm Access to another Business</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
                >{{ selectedBusiness.BusinessName }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card-text class="headline">
                  <v-row>
                    <v-col sm="12" md="1" class="body-1"
                      >ID: {{ selectedBusiness.ID }}</v-col
                    >
                    <v-col sm="12" md="3" class="body-1">{{
                      selectedBusiness.BusinessName
                    }}</v-col>
                    <v-col sm="12" md="3" class="body-1">{{
                      selectedBusiness.Email
                    }}</v-col>
                    <v-col sm="12" md="5" class="body-1"
                      >Created On:
                      {{ selectedBusiness.InsertDate | dateformat }}</v-col
                    >
                  </v-row>
                  <v-divider class="secondary my-8"></v-divider>
                  <v-row>
                    <v-col sm="12">
                      <v-data-table
                        :headers="filteredHeaders"
                        :items="selectedBusiness.License"
                        :search="searchText"
                        class="pt-4"
                        :loading="loading"
                        dense
                      >
                        <template v-slot:item.edit="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="goToLicense(item)"
                              >
                                <v-icon class="clickable" color="primary"
                                  >mdi-eye</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>View License</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.InsertDate="{ item }">
                          {{ item.InsertDate | dateformat }}
                        </template>
                        <template v-slot:item.IsActive="{ item }">{{
                          item.IsActive ? 'Active' : 'Inactive'
                        }}</template>
                        <template v-slot:item.delete="{ item }">
                          <v-tooltip v-if="item.ID > 0" bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="deleteConfirm(item)"
                              >
                                <v-icon class="clickable" color="primary"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Delete Business</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog" width="750">
      <AccountCreationForm
        :selectedBusiness="editBusiness"
        :title="title"
        :editPage="editPage"
        v-on:closeDialog="closeDialog"
      ></AccountCreationForm>
    </v-dialog>
  </div>
</template>
<script>
import { authMethods, authComputed } from '@state/helpers'
import AccountCreationForm from '@components/agriculture-main/business/user/accountCreationForm'
import { get, sync, commit, call } from 'vuex-pathify'
import Business from '@classes/Business'
import UserBusiness from '@classes/UserBusiness'
export default {
  page: {
    title: 'UserAccountDetails',
  },
  components: {
    AccountCreationForm,
  },
  data() {
    return {
      valid: true,
      businessDialog: false,
      deleteDialog: false,
      dialog: false,
      panel: [0, 1],
      searchText: '',
      modalText: '',
      title: 'Edit Business Information',
      editPage: true,
      editBusiness: {},
      unconfirmedBusinesses: [],
    }
  },
  created() {
    if (this.isBusinessUser) {
      this.getBusinessesToConfirm()
    }
    //this.loadSelectedBusiness()
  },
  computed: {
    ...authComputed,
    ...get('business', ['selectedBusiness', 'saving', 'loading']),
    ...get('hempLicense', ['hempLicense', 'selectedHempLicense']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'License Type',
          align: 'left',
          sortable: true,
          value: 'LicenseType.Description',
          visible: true,
        },
        {
          text: 'Date Created',
          align: 'left',
          sortable: true,
          value: 'InsertDate',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('business', [
      'saveBusiness',
      'deleteBusiness',
      'loadSelectedBusiness',
      'clearLookupCacheAndReloadBusiness',
    ]),
    openDialog() {
      this.editBusiness = new Business(this.selectedBusiness)
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    goToLicense(item) {
      console.log(item)
      if (item && item.HempLicense && item.HempLicense.ID > 0) {
        this.$router.push({
          path: `/HempApplication/${item.BusinessID}/${item.HempLicense.ID}`,
        })
      }
    },
    getBusinessesToConfirm() {
      let url = `UserBusiness/Unconfirmed`
      this.$axios.get(url).then(
        (res) => {
          this.unconfirmedBusinesses = res.data
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.log(error)
        }
      )
    },
  },
  watch: {},
}
</script>
<style scoped></style>
