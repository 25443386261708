<template>
  <v-container>
    <v-row>
      <v-col sm="12" v-if="selectedBusiness">
        <UserAccount></UserAccount>
        <BusinessUsers v-if="isInternalUser || isBusinessAdminForBusiness"></BusinessUsers>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="businessSelectDialog"
      @keydown.esc="businessSelectDialog = false"
      width="850"
    >
      <v-card class="elevation-3">
        <v-card-title class="primary headline">
          <h3 class="primary headline text-left white--text">Select business to manage.</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <!-- current businesses -->
          <v-data-table :headers="filteredHeaders" :items="businesses" class="pt-4" dense>
            <template v-slot:item.edit="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn small color="primary" v-on="on" icon @click="goToBusiness(item)">
                    <v-icon class="clickable" color="primary">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Edit Business Information</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <!-- confirm pending businesses link? -->
          <v-card-actions></v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { authMethods, authComputed } from '@state/helpers'
import UserAccount from '@components/agriculture-main/business/user/userAccount'
import BusinessUsers from '@components/agriculture-main/business/user/businessUsers'
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  page: {
    title: 'UserAccount',
  },
  components: {
    UserAccount,
    BusinessUsers,
  },
  data() {
    return {
      businessSelectDialog: false,
    }
  },
  created() {
    if (this.$route.params.businessId) {
      this.loadSelectedBusiness(this.$route.params.businessId)
    } else {
      this.setSelectedBusiness(null)
      //add detection of business associated with public users - automatically get if there is  one, open dialog select if there is more than one
      if (this.currentUser && this.currentUser.UserBusinessUser.length > 0) {
        let confirmedBusinesses = this.currentUser.UserBusinessUser.filter(
          (item) => item.IsConfirmed
        )
        let unconfirmedBusinesses = this.currentUser.UserBusinessUser.filter(
          (item) => !item.IsConfirmed
        )
        if (confirmedBusinesses.length == 1) {
          var businessId = this.currentUser.UserBusinessUser[0].BusinessID
          //if they only have one business we load it
          this.$router.replace({
            path: `/userBusiness/${businessId}`,
          })
        } else if (confirmedBusinesses.length > 1) {
          // console.log('openDialog')
          //the user has multiple businesses and we need to select one
          //open dialog for selecting business that will route to that id
          this.businessSelectDialog = true
        } else if (unconfirmedBusinesses.length > 0) {
          this.$router.push({
            path: `/confirmBusiness`,
          })
        } else {
          // console.log('openDialog')
          //the user has multiple businesses and we need to select one
          //open dialog for selecting business that will route to that id
          this.businessSelectDialog = true
        }
      } else if (!this.isAdmin && !this.isAgUser) {
        // console.log(this.isAdmin, this.isAgUser)
        this.$router.push({ path: '/accountCreation' })
      } else {
        //redirect to inbox?
        this.$router.push({ path: '/applicationInbox' })
      }
    }
  },
  computed: {
    ...authComputed,
    ...get('business', ['selectedBusiness']),
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'I D',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Business Name',
          align: 'center',
          sortable: true,
          value: 'BusinessName',
          visible: true,
        },
        {
          text: 'Address',
          align: 'center',
          sortable: true,
          value: 'PhysicalAddress',
          visible: true,
        },
      ]
    },
    businesses() {
      return this.currentUser.UserBusinessUser.map((item) => item.Business)
    },
    isBusinessAdminForBusiness() {
      if (this.currentUser && this.currentUser.UserBusinessUser) {

        let userBusiness = this.currentUser.UserBusinessUser.find(item => item.BusinessID == this.selectedBusiness.ID)
        if (userBusiness && userBusiness.BusinessRoleID == 2 ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    loadSelectedBusiness: call('business/loadSelectedBusiness'),
    setSelectedBusiness: call('business/setSelectedBusiness'),
    goToBusiness(entry) {
      this.$router.push({
        path: `/userBusiness/${entry.ID}`,
      })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
